import React from "react"
import { graphql, Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { renderToStaticMarkup } from "react-dom/server"
import { SideMenu } from "@fastly/consistently"

import Alert from "~components/alert"
import T from "~components/translator"
import mailto from "~components/mailto"
import Frontmatter from "~components/frontmatter"

import * as Includes from "~components/includes"
import FrontmatterPageContext from "~context/FakePageContext"
import LanguageDropdown from "~components/lang-dropdown"
import { BreadcrumbsPrimary } from "~components/Breadcrumbs/Breadcrumbs"

import DisplayGuidesInPrimary, {
  grouped_guides_to_sec_cards,
  primary_orphans_to_cards,
} from "~components/guides_in_primary"

// @TODO extract this out of gatsby-node for reuse other places like here
// import { mdPathToPageOptions } from "../../shared_code"

import FeedbackFormKeep from "~components/feedback-formkeep"

import { SeoHead } from "~components/Head/SeoHead"
import { CommonHead } from "~components/Head/CommonHead"
import LayoutCore from "./layout-core"
import { sideMenu } from "~components/SideMenu/side-menu.css"
import { main, mainArea } from "~vanilla/layout-default.css"

import NavGuideData from "~data/nav_security.yml"
import { SideMenuSwitcher } from "~components/SideMenu/side-menu"
import { TranslationJA } from "~components/includes"

const LayoutGuidePrimary = ({ pageContext, location, data }) => {
  const { frontmatter, body } = pageContext
  frontmatter["section_safe"] = frontmatter["section"] || "none"

  // for MDXProvider later
  let mdx_components = { T, Alert, mailto, Frontmatter, Link, ...Includes }

  // this was a old product page thing?
  let conditionalFeedback = <></>
  if ("feedback" in frontmatter && frontmatter["feedback"] === true) {
    conditionalFeedback = <FeedbackFormKeep id="7a402cfe248d" pathname={location.pathname} />
  }

  let thisPrimaryGuides = data.guides_in_this_primary //.find( ggip => ggip.frontmatter.primary_nav === pkey)

  let mySecOrder = NavGuideData.filter((prime) => prime.crumb === frontmatter.cat_id)[0]["secondary-nav"]

  let sec_cards = grouped_guides_to_sec_cards(frontmatter, thisPrimaryGuides?.nodes, data.secondaries.nodes, mySecOrder)

  let primary_orphans = primary_orphans_to_cards(frontmatter, data.primary_orphans)

  return (
    <FrontmatterPageContext.Provider value={frontmatter}>
      <LayoutCore>
        <div className={`${mainArea} grid-main`}>
          <SideMenu
            className={`${sideMenu} main-nav section-${frontmatter.section_safe}`}
            aria-label="Navigation between documents"
            data-swiftype-index="false"
          >
            <SideMenuSwitcher />
          </SideMenu>

          <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
            <div className="content-crumb">
              <BreadcrumbsPrimary />
            </div>

            <div className="content-lang" data-swiftype-index="false">
              <LanguageDropdown location={location} />
            </div>

            <div className="content-content">
              <DisplayGuidesInPrimary
                rootPage="true"
                frontmatter={frontmatter}
                body={
                  <MDXProvider components={mdx_components}>
                    <MDXRenderer>{body}</MDXRenderer>
                  </MDXProvider>
                }
                sec_cards={sec_cards}
                primary_orphans={primary_orphans}
              />
            </div>

            <div className="content-after" data-swiftype-index="false">
              {frontmatter?.lang === "ja" && <TranslationJA />}

              {conditionalFeedback}
            </div>
          </main>
        </div>
      </LayoutCore>
    </FrontmatterPageContext.Provider>
  )
}

export default LayoutGuidePrimary

export const query = graphql`
  query($primary_nav: String, $re_src_collection: String, $re_sec_collection: String) {
    guides_in_this_primary: allMdx(
      filter: {
        frontmatter: { primary_nav: { eq: $primary_nav }, unlisted: { ne: true }, orphan: { ne: true } }
        fileAbsolutePath: { regex: $re_src_collection }
      }
      sort: { fields: [frontmatter___order, slug], order: [ASC, ASC] }
    ) {
      nodes {
        slug
        frontmatter {
          primary_nav
          secondary_nav
          header
          unlisted
        }
      }
    }
    secondaries: allMdx(filter: { fileAbsolutePath: { regex: $re_sec_collection } }, sort: { fields: slug }) {
      nodes {
        fileAbsolutePath
        slug
        body
        frontmatter {
          name
          crumb
          description
        }
      }
    }
    primary_orphans: allMdx(
      filter: {
        frontmatter: { primary_nav: { eq: $primary_nav }, orphan: { eq: true }, unlisted: { ne: true } }
        fileAbsolutePath: { regex: $re_src_collection }
      }
      sort: { fields: [frontmatter___order, slug], order: [ASC, ASC] }
    ) {
      nodes {
        fileAbsolutePath
        slug
        excerpt
        frontmatter {
          primary_nav
          secondary_nav
          header
          unlisted
        }
      }
    }
  }
`

export const Head = (props) => {
  const { frontmatter } = props.pageContext
  const { pathname } = props.location

  const title_suffix = renderToStaticMarkup(
    <T noparse={true} lang={frontmatter.lang}>
      title-suffix-guides
    </T>
  )
  const full_title = `${frontmatter["section_name"]} | ${title_suffix}`

  let langlinks = ""
  if (frontmatter?.i18n) {
    langlinks = []
    langlinks.push(<link rel="alternate" hrefLang={frontmatter["lang"]} href={props.location.pathname} data-self="1" />)

    let re = new RegExp(`^/${frontmatter.lang}/`)
    Object.values(frontmatter.i18n).forEach((langkey) => {
      let langpath = pathname.replace(re, `/${langkey}/`)

      // @TODO: this needs to be a full absolute path, need to query sitemetadata etc
      langlinks.push(<link rel="alternate" hrefLang={langkey} href={langpath} />)
    })
  }

  const noindex =
    frontmatter?.sitemap == false || frontmatter?.unlisted == true || frontmatter?.noindex == true ? (
      frontmatter?.forceindex ? (
        <></>
      ) : (
        <meta name="robots" content="noindex" />
      )
    ) : (
      <></>
    )

  return (
    <>
      <title>{full_title}</title>
      {langlinks}
      {noindex}
      <CommonHead />
      <SeoHead frontmatter={{ title: full_title }} {...props} />
    </>
  )
}